import { ProjectPayItem } from "modules/projects/models";
import { FilterOperation } from "api";

export interface PayItemFilter {
  fieldName: string;
  filterOperation: FilterOperation;
  values: string[];
}

export interface PayItemSource {
  type: QuickPricingSourceOptions;
  payItemLibraryId: string;
  filters?: PayItemFilter[];
}

export interface PayItemAverages {
  unitPrice?: number;
  unitCost?: number;
  lowUnitPrice?: number;
  highUnitPrice?: number;
  lowUnitCost?: number;
  highUnitCost?: number;
}

export interface PayItemInfo {
  code: string;
  description: string;
  unit: string;
  averages: PayItemAverages;
  manualEntry?: boolean;
}

export interface QuickPriceSheetCreateDto {
  payItemSource: PayItemSource;
  projectPayItems: ProjectPayItem[];
  linkedProjectId: string;
}

export interface QuickPriceSheetReadDto {
  id: string;
  payItemSource: PayItemSource;
  projectPayItems: ProjectPayItem[];
  linkedProjectId: string;
  lastModifiedByUserId: string;
  lastModified: Date;
  dateCreated: Date;
  businessUnitId: string;
  deleted: boolean;
}

export enum QuickPricingSourceOptions {
  None = 0,
  PayItemLibrary = 1,
  EstimateHistory = 2
}
