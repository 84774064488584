import axios, { AxiosInstance, AxiosPromise } from "axios";
import { ModelMappingService } from "core/services/model-mapping-service";
import { UsableAccount, HcssCalendarAccount } from "../models";
import { CreateAccountResponse } from "../models/CreateAccountResponse";
import { NylasAccount } from "../models/NylasAccount";
import { NylasCalendar } from "../models/NylasCalendar";

export class HcssConnectAccountService {
  instance: AxiosInstance;
  baseUrl: string;
  token: string;
  client: AxiosInstance;
  mapperService: ModelMappingService;

  constructor(baseUrl: string, token: string) {
    this.instance = axios.create();
    this.baseUrl = baseUrl;
    this.token = token;
    this.client = axios.create();
    this.mapperService = new ModelMappingService();

    this.client.interceptors.request.use(config => {
      config.headers = { "X-Kloudless-Raw-Data": true };
      config.headers.Authorization = `Bearer ${this.token}`;
      return config;
    });

    this.instance.interceptors.response.use(
      response => response,
      error => {
        console.error(error);
        if (error.response.status === 401) {
          window.location.reload();
        }

        return Promise.reject(error);
      }
    );
  }

  async getNylasAccounts(hcssCalendarAccounts: HcssCalendarAccount[]) {
    const promises = [] as AxiosPromise<NylasAccount>[];
    hcssCalendarAccounts.forEach(account => {
      promises.push(this.getNylasAccountDetails(account.grantId));
    });
    const responses = await Promise.all(promises);
    return responses.map(response =>
      this.mapperService.mapNylasAccountToUsableAccount(response.data)
    );
  }

  async getNylasCalendarsForNylasAccounts(accounts: UsableAccount[]) {
    const promises: Promise<NylasCalendar[]>[] = [];
    let calendars: NylasCalendar[] = [];
    accounts.forEach(account => {
      promises.push(this.getNylasAccountCalendars(account.id));
    });
    const responses = await Promise.allSettled(promises);
    responses.forEach(response => {
      if (response.status === "fulfilled") {
        const cals = response.value;
        calendars = [...calendars, ...cals];
      }
    });
    return calendars;
  }

  async connectAccount(redirectUrl: string) {
    const response = await this.client.post<CreateAccountResponse>(
      `${this.baseUrl}/api/v3/accounts/`,
      { redirect: redirectUrl }
    );
    return response.data.connectionUrl;
  }

  private async getNylasAccountCalendars(accountId: string) {
    const response = await this.client.get<NylasCalendar[]>(
      `${this.baseUrl}/api/v3/calendars`,
      {
        headers: {
          grantId: accountId
        }
      }
    );
    return response.data;
  }

  async getHcssCalendarAccounts() {
    return this.client.get<HcssCalendarAccount[]>(
      `${this.baseUrl}/api/v3/accounts`
    );
  }

  private getNylasAccountDetails(accountId: string) {
    return this.client.get<NylasAccount>(
      `${this.baseUrl}/api/v3/grants/${accountId}/`
    );
  }

  async disconnectNylasAccount(accountId: string) {
    return await this.client.delete(
      `${this.baseUrl}/api/v3/accounts/${accountId}`
    );
  }
}
