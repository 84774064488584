import constate from "constate";
import { UsableAccount, HcssCalendarAccount } from "./models";
import { notify } from "hcss-components";
import { useHcssUser, useHcssToken } from "modules/account";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HcssConnectAccountService } from "./services/account-service";
import config from "config";
import { UserType } from "core";
import { NylasCalendar } from "./models/NylasCalendar";
import { strings } from "localization";

const useHcssConnect = () => {
  const token = useHcssToken();
  const user = useHcssUser();
  const [loadingNylasAccounts, setLoadingNylasAccounts] = useState<boolean>(
    true
  );
  const [
    loadingHcssCalendarAccounts,
    setLoadingHcssCalendarAccounts
  ] = useState<boolean>(true);
  const [loadingNylasCalendars, setLoadingNylasCalendars] = useState<boolean>(
    true
  );
  const [loadingRedirect, setLoadingRedirect] = useState<boolean>(false);
  const [nylasAccounts, setNylasAccounts] = useState<UsableAccount[]>([]);
  const [nylasCalendars, setNylasCalendars] = useState<NylasCalendar[]>([]);

  const hcssConnectApi = useMemo(
    () => new HcssConnectAccountService(config.endpoints.HCSSCONNECT, token),
    [token]
  );

  const redirectQueryArg = "kloudlessRedirect=true";

  const loadHcssCalendarAccounts = useCallback(async () => {
    setLoadingHcssCalendarAccounts(true);
    try {
      const accs = await hcssConnectApi.getHcssCalendarAccounts();
      setLoadingHcssCalendarAccounts(false);
      return accs.data;
    } catch (error) {
      console.error(error);
      notify("danger", strings.hcssConnect.errors.fetchingAccount);
      setLoadingHcssCalendarAccounts(false);
    }
  }, [hcssConnectApi]);

  const loadNylasAccounts = useCallback(
    async (hcssAccounts: HcssCalendarAccount[]) => {
      setLoadingNylasAccounts(true);
      try {
        const accounts = await hcssConnectApi.getNylasAccounts(hcssAccounts);
        if (accounts.some(acc => acc.isBad)) {
          notify("danger", strings.hcssConnect.errors.fetchingAccount);
        }
        setNylasAccounts(accounts);
      } catch (error) {
        console.error(error);
        notify("danger", strings.hcssConnect.errors.fetchingAccount);
      }
      setLoadingNylasAccounts(false);
    },
    [hcssConnectApi]
  );

  const loadNylasCalendars = useCallback(async () => {
    setLoadingNylasCalendars(true);
    try {
      const calendars = await hcssConnectApi.getNylasCalendarsForNylasAccounts(
        nylasAccounts
      );
      setNylasCalendars(calendars);
    } catch (error) {
      console.error(error);
      notify("danger", "Error fetching calendars");
    }
    setLoadingNylasCalendars(false);
  }, [nylasAccounts, hcssConnectApi]);

  useEffect(() => {
    const loadData = async () => {
      const hcssCalendarAccounts = await loadHcssCalendarAccounts();
      if (hcssCalendarAccounts) {
        await loadNylasAccounts(hcssCalendarAccounts);
      }
    };
    if (user.type !== UserType.Guest) {
      void loadData();
    }
  }, [loadHcssCalendarAccounts, loadNylasAccounts, user.type]);

  useEffect(() => {
    if (nylasAccounts) void loadNylasCalendars();
  }, [nylasAccounts, loadNylasCalendars]);

  const deleteNylasAccount = async (accountId: string) => {
    try {
      const {
        data: deletedAccount
      } = await hcssConnectApi.disconnectNylasAccount(accountId);
      if (deletedAccount !== null) {
        const tempAccounts = [...nylasAccounts];
        const deleteIndex = tempAccounts.findIndex(acc => acc.id === accountId);
        if (deleteIndex !== -1) {
          tempAccounts.splice(deleteIndex, 1);
          notify("success", "Account link deleted successfully");
          setNylasAccounts(tempAccounts);
        }
      }
    } catch (error) {
      notify("danger", "Unable to delete account link");
    }
  };

  const connectAccount = async () => {
    setLoadingRedirect(true);
    try {
      const connectUrl = !window.location.href.includes("?")
        ? `${window.location.href}?${redirectQueryArg}`
        : `${window.location.href}&${redirectQueryArg}`;
      const redirectUrl = await hcssConnectApi.connectAccount(connectUrl);
      window.location.assign(redirectUrl);
    } catch (error) {
      notify("danger", "Error connecting new account");
    }
    setLoadingRedirect(false);
  };

  return {
    loading:
      loadingHcssCalendarAccounts ||
      loadingNylasAccounts ||
      loadingNylasCalendars ||
      loadingRedirect,
    nylasAccounts,
    nylasCalendars,
    deleteNylasAccount,
    connectAccount,
    loadNylasCalendars
  };
};

const [provider, context] = constate(useHcssConnect);
export const HcssConnectProvider = provider;
export const useHcssConnectContext = context;
