import React, { useCallback, useState } from "react";
import AsyncSelect from "react-select";
import { Props as ReactSelectProps } from "react-select";
import { WithId } from "../../../core/models";
import { Calendar } from "../../../api";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { actions } from "../state";
import ConfirmDelete from "../../../core/components/modals/ConfirmDelete";
import { Icon, Button } from "hcss-components";
import { strings } from "localization";
import { useHcssConnectContext } from "core/services/hcss-connect";
import { debounce } from "lodash-es";
import { ListLoadingOverlay } from "core";
import { track } from "logging";
import { NylasCalendar } from "core/services/hcss-connect/models/NylasCalendar";

export interface HcssConnectSelectProps {
  viewId: string;
  calendars: NylasCalendar[];
  selectedCalendar?: WithId<Calendar>;
}

// @ts-ignore
const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export const NylasSelect = ({
  viewId,
  calendars,
  selectedCalendar
}: HcssConnectSelectProps) => {
  const {
    loadNylasCalendars,
    nylasAccounts,
    loading
  } = useHcssConnectContext();
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [newCalId, setNewCalId] = useState<string>();
  const options: GroupedOptions[] = [];
  const dispatch = useDispatch();

  calendars
    .filter(
      c =>
        c.name.toLowerCase() !== "birthdays" &&
        c.name.toLowerCase() !== "united states holidays" &&
        !c.read_only
    )
    .forEach(c => {
      const accountForCal = nylasAccounts.find(
        nylasAccount => nylasAccount.id === c.grantId
      );
      const groupLabel = `${accountForCal?.serviceName}: ${accountForCal?.emailAddress}`;
      let group = options.find(o => o.label === groupLabel);
      if (!group) {
        group = { label: groupLabel, options: [] };
        options.push(group);
      }
      group.options.push({ value: c.id, label: c.name ?? "(no name)" });
    });

  const selected = calendars.find(
    cal => cal.id === selectedCalendar?.calendarId
  );

  const selectedOption: ReactSelectProps["value"] = selected
    ? { value: selected.id, label: selected.name }
    : undefined;

  const handleChange = (e: any) => {
    const calId = e?.value;
    if (selectedCalendar?.calendarId === calId) return;
    if (selectedCalendar?.calendarId) {
      setNewCalId(calId);
      setShowReplaceModal(true);
    } else {
      saveCalendar(calId);
    }
  };

  const saveCalendar = (id: string) => {
    const cal = calendars.find(c => c.id === id);
    if (cal) {
      dispatch(
        actions.saveCalendar.request({
          viewId,
          accountId: cal.grantId,
          calendarId: cal.id,
          isNylas: true
        })
      );
      track("CALENDAR CONNECTED", {
        module: "projects",
        redux: false,
        type: "Nylas"
      });
    }
  };

  const refreshCalendars = useCallback(
    debounce(loadNylasCalendars, 5000, { leading: true }),
    []
  );

  return (
    <>
      <StyledSelect
        classNamePrefix="react-select"
        className="react-select-container"
        options={options}
        value={selectedOption}
        onChange={handleChange}
        formatGroupLabel={formatGroupLabel}
        onFocus={refreshCalendars}
        isLoading={loading}
        components={{ LoadingIndicator }}
        defaultOptions
      />
      <ConfirmDelete
        show={showReplaceModal}
        handleClose={() => setShowReplaceModal(false)}
        title={strings.calendars.card.createLink}
        style={{ zIndex: 9999 }}
        footer={
          <div>
            <Button
              onClick={() => {
                if (newCalId) saveCalendar(newCalId);
                setShowReplaceModal(false);
              }}
              hcssStyle="Theme"
            >
              <Icon name="trash-o" />
              &nbsp;{strings.calendars.card.continue}
            </Button>
            <Button
              onClick={() => setShowReplaceModal(false)}
              hcssStyle="ThemeInverted"
            >
              <Icon name="times" />
              &nbsp;{strings.calendars.card.cancel}
            </Button>
          </div>
        }
      >
        <p>{strings.calendars.card.deleteHcssConnectWarning}</p>
        <p>{strings.calendars.card.deleteNewHcssConnectLink}</p>
      </ConfirmDelete>
    </>
  );
};

const LoadingIndicator = () => {
  return <ListLoadingOverlay message={" "} />;
};

interface GroupedOptions {
  label: string;
  options: SelectOption[];
}
interface SelectOption {
  label: string;
  value: string;
}

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginLeft: 0,
  paddingLeft: 0
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em"
};
const StyledSelect = styled(AsyncSelect)`
  div.react-select__menu {
    z-index: 11;
  }
`;
